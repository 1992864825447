.ExamSigninPage-root-grid {
  width: 100%;
  height: 100vh;
}

.ExamSigninPage-main-paper {
  width: 30%;
}

.ExamSigninPage-title-box {
  padding: 5px;
  background-color: #2196f3;
}

.ExamSigninPage-form-grid {
  padding: 10px;
}