.UnautholizedPage-root-grid {
  height: 100vh;
  width: 100%;
  background-color: #263238;
}

.UnautholizedPage-main-paper {
  width: 30%;
}

.UnautholizedPage-body-div {
  padding: 10px;
}

.UnautholizedPage-title-typo {
  padding: 5px 10px 0 10px;
  background-color: #757575;
  color: whitesmoke;
}

.UnautholizedPage-title-icon {
  margin: 0 5px 0 0;
}

.UnautholizedPage-back-button {
  margin: 10px 0 0 0;
}