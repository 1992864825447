.GradingExamPage-filter-paper {
  width: 100%;
  padding: 10px 0;
  margin-bottom: 10px;
}

.GradingExamPage-main-div {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.GradingExamPage-exams-table {
  width: 100%;
  height: calc(100vh - 370px);
  overflow-y: scroll;
}

.GradingExamPage-exams-table::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.GradingExamPage-exams-table::-webkit-scrollbar-track {
  background-color: #fafafa;
}

/* Handle */
.GradingExamPage-exams-table::-webkit-scrollbar-thumb {
  background-color: #cfd8dc; 
  border-radius: 10px;
}

/* Handle on hover */
.GradingExamPage-exams-table::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd;
}