.ViewerDisplay-root {
  height: 100%;
}

.display {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
}

.imageCanvas {
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.originalImage {
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.drawnImage {
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.selectedDrawnImage {
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.infoImage {
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.annotationImage {
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.drawCanvas {
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.ViewerDisplay-overlay-bottom-left {
  position: absolute;
  bottom: 70px;
  left: 10px;
  z-index: 101;
}

.ViewerDisplay-ImageInfo {
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px 20px 10px 10px;
  z-index: 101;
}

.ViewerDisplay-PlayingSpeed-Select {
  color: #03a9f4;
}

.ViewerDisplay-PlayingSpeed-Select::before {
  border-color: #03a9f4;
}

.ViewerDisplay-PlayingSpeed-Select::after {
  border-color: #03a9f4;
}