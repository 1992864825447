.StudentViewer-root-grid {
  height: 100vh;
  background-color: black;
}

.StudentViewer-panel-grid {
  height: 100vh;
  background-color: #424242;
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 5px;
}
.StudentViewer-list-grid {
  width: 100%;
  height: calc(100vh - 10px);
  background-color: white;
}

.StudentViewer-title-stack {
  margin-bottom: 5px;
}

.StudentViewer-description {
  height: 44px;
  white-space: pre-wrap;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.StudentViewer-detection-table {
  width: 100%;
  height: calc(100vh - 300px);
  margin-bottom: 5px;
  overflow-y: scroll;
}

.StudentViewer-detection-table::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.StudentViewer-detection-table::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
.StudentViewer-detection-table::-webkit-scrollbar-thumb {
  background-color: #cfd8dc; 
  border-radius: 2px;
}

/* Handle on hover */
.StudentViewer-detection-table::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd; 
}

.StudentViewer-identification-main-paper {
  width: 100%;
  height: calc(100vh - 300px);
  margin-bottom: 5px;
  overflow-y: scroll;
}

.StudentViewer-identification-main-paper::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.StudentViewer-identification-main-paper::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
.StudentViewer-identification-main-paper::-webkit-scrollbar-thumb {
  background-color: #cfd8dc; 
  border-radius: 2px;
}

/* Handle on hover */
.StudentViewer-identification-main-paper::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd; 
}