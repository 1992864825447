.NotFoundPage-root-grid {
  height: 100vh;
  width: 100%;
  background-color: #263238;
}

.NotFoundPage-main-paper {
  width: 30%;
}

.NotFoundPage-body-div {
  padding: 10px;
}

.NotFoundPage-title-typo {
  padding: 5px 10px 0 10px;
  background-color: #757575;
  color: whitesmoke;
}

.NotFoundPage-title-icon {
  margin: 0 5px 0 0;
}

.NotFoundPage-back-button {
  margin: 10px 0 0 0;
}