.CaseNotExistDialog-table {
  width: 100%;
  height: calc(100vh - 250px);
  overflow-y: scroll;
}

.CaseNotExistDialog-table::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.CaseNotExistDialog-table::-webkit-scrollbar-track {
  background-color: #fafafa;
}

/* Handle */
.CaseNotExistDialog-table::-webkit-scrollbar-thumb {
  background-color: #e0e0e0; 
  border-radius: 10px;
}

/* Handle on hover */
.CaseNotExistDialog-table::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd; 
}