.VideoDialog-Dialog-Content {
  padding: 20px;
  background-color: black;
  overflow: scroll;
}

.VideoDialog-Dialog-Content::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.VideoDialog-Dialog-Content::-webkit-scrollbar-track {
  background-color: black;
}

.VideoDialog-Dialog-Content::-webkit-scrollbar-corner { 
  background-color: black;
}

/* Handle */
.VideoDialog-Dialog-Content::-webkit-scrollbar-thumb {
  background-color: #424242; 
  border-radius: 10px;
}

/* Handle on hover */
.VideoDialog-Dialog-Content::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e; 
}