.SigninPage-root-grid {
  width: 100%;
  height: 100vh;
}

.SigninPage-main-paper {
  width: 30%;
}

.SigninPage-title-box {
  padding: 5px;
  background-color: #2196f3;
}

.SigninPage-form-grid {
  padding: 10px;
}