.ExamFinishedPage-root-grid {
  height: 100vh;
}

.ExamFinishedPage-main-paper {
  min-width: 50%;
  max-width: 70%;
  min-height: 30%;
  max-height: 50%;
}

.ExamFinishedPage-main-title {
  margin: 20px;
}