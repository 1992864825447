.ExamStudentResultRow-main-paper {
  margin: 10px 0;
  padding: 10px;
}

.ExamStudentResultRow-main-table {
  margin-top: 10px;
  width: 100%;
  max-height: calc(100vh - 400px);
  overflow-y: scroll;
}

.ExamStudentResultRow-main-table::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.ExamStudentResultRow-main-table::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
.ExamStudentResultRow-main-table::-webkit-scrollbar-thumb {
  background-color: #cfd8dc; 
  border-radius: 2px;
}

/* Handle on hover */
.ExamStudentResultRow-main-table::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd; 
}