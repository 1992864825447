.ExamStudentDialog-exams-table {
  width: 100%;
  height: calc(100vh - 230px);
}

.ExamStudentDialog-dropzone {
  align-items: center;
  padding: 0 12px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #bdbdbd;
  border-style: dashed;
  background-color: #eeeeee;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;
}

.ExamStudentDialog-dropzone-text {
  margin: 5px 0;
}

.ExamStudentDialog-table {
  width: 100%;
  height: calc(100vh - 250px);
  overflow-y: scroll;
}

.ExamStudentDialog-table::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.ExamStudentDialog-table::-webkit-scrollbar-track {
  background-color: #fafafa;
}

/* Handle */
.ExamStudentDialog-table::-webkit-scrollbar-thumb {
  background-color: #e0e0e0; 
  border-radius: 10px;
}

/* Handle on hover */
.ExamStudentDialog-table::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd; 
}