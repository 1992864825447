.ExamTemplateViewer-root-grid {
  height: 100vh;
  background-color: black;
}

.ExamTemplateViewer-panel-grid {
  height: 100vh;
  background-color: #424242;
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 5px;
}

.ExamTemplateViewer-list-grid {
  width: 100%;
  height: calc(100vh - 10px);
  background-color: white;
}

.ExamTemplateViewer-category-paper {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
}

.ExamTemplateViewer-dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #bdbdbd;
  border-style: dashed;
  background-color: #eeeeee;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;
}

.ExamTemplateViewer-aivideo-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ExamTemplateViewer-button-grid {
  width: 100%;
  height: 100%;
  background-color: #eceff1;
  overflow: hidden;
}

.ExamTemplateViewer-exam-table {
  width: 100%;
  height: calc(100vh - 420px);
  margin-bottom: 10px;
  overflow-y: scroll;
}

.ExamTemplateViewer-exam-table::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.ExamTemplateViewer-exam-table::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
.ExamTemplateViewer-exam-table::-webkit-scrollbar-thumb {
  background-color: #cfd8dc; 
  border-radius: 2px;
}

/* Handle on hover */
.ExamTemplateViewer-exam-table::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd; 
}