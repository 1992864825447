.ExamTemplateVideoUploaderDialog-csv-dropzone {
  width: 50%;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #bdbdbd;
  border-style: dashed;
  background-color: #eeeeee;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;
}

.ExamTemplateVideoUploaderDialog-csv-dropzone-text {
  margin: 5px 0;
}

.ExamTemplateVideoUploaderDialog-videos-dropzone {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #bdbdbd;
  border-style: dashed;
  background-color: #2196f3;
  color: white;
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;
}

.ExamTemplateVideoUploaderDialog-videos-dropzone-text {
  margin: 5px 0;
}

.ExamTemplateVideoUploaderDialog-csv-table {
  width: 100%;
  height: calc(100vh - 310px);
  overflow-y: scroll;
}

.ExamTemplateVideoUploaderDialog-csv-table::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.ExamTemplateVideoUploaderDialog-csv-table::-webkit-scrollbar-track {
  background-color: #fafafa;
}

/* Handle */
.ExamTemplateVideoUploaderDialog-csv-table::-webkit-scrollbar-thumb {
  background-color: #e0e0e0; 
  border-radius: 10px;
}

/* Handle on hover */
.ExamTemplateVideoUploaderDialog-csv-table::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd; 
}