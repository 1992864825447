.GradingDetectionAnswerPanel-main-paper {
  margin: 10px 0;
  padding: 10px;
}

.GradingDetectionAnswerPanel-action-stack {
  margin-bottom: 10px;
}

.GradingDetectionAnswerPanel-image-table {
  margin-bottom: 20px;
  width: 100%;
}

.GradingDetectionAnswerPanel-answer-table {
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
}

.GradingDetectionAnswerPanel-answer-table::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.GradingDetectionAnswerPanel-answer-table::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
.GradingDetectionAnswerPanel-answer-table::-webkit-scrollbar-thumb {
  background-color: #cfd8dc; 
  border-radius: 2px;
}

/* Handle on hover */
.GradingDetectionAnswerPanel-answer-table::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd; 
}

.GradingDetectionAnswerPanel-score-table {
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
}

.GradingDetectionAnswerPanel-score-table::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.GradingDetectionAnswerPanel-score-table::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
.GradingDetectionAnswerPanel-score-table::-webkit-scrollbar-thumb {
  background-color: #cfd8dc; 
  border-radius: 2px;
}

/* Handle on hover */
.GradingDetectionAnswerPanel-score-table::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd; 
}