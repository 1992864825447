.GradingAnswerViewer-root-grid {
  height: 100vh;
  background-color: black;
}

.GradingAnswerViewer-panel-grid {
  height: 100vh;
  background-color: #424242;
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 5px;
}

.GradingAnswerViewer-list-grid {
  width: 100%;
  height: calc(100vh - 10px);
  background-color: white;
}

.GradingAnswerViewer-answer-table {
  width: 100%;
  height: 200px;
  overflow-y: scroll;
}

.GradingAnswerViewer-answer-table::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.GradingAnswerViewer-answer-table::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
.GradingAnswerViewer-answer-table::-webkit-scrollbar-thumb {
  background-color: #cfd8dc; 
  border-radius: 2px;
}

/* Handle on hover */
.GradingAnswerViewer-answer-table::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd; 
}

.GradingAnswerViewer-drawing-table {
  width: 100%;
  height: calc(100vh - 570px);
  margin-top: 5px;
  margin-bottom: 5px;
  overflow-y: scroll;
}

.GradingAnswerViewer-drawing-table::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.GradingAnswerViewer-drawing-table::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
.GradingAnswerViewer-drawing-table::-webkit-scrollbar-thumb {
  background-color: #cfd8dc; 
  border-radius: 2px;
}

/* Handle on hover */
.GradingAnswerViewer-drawing-table::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd; 
}