.HealthCheckExamDialog-exam-paper {
  height: 100px;
  padding: 5px 10px;
  margin-bottom: 10px;
  overflow-y: scroll;
}

.HealthCheckExamDialog-exam-paper::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.HealthCheckExamDialog-exam-paper::-webkit-scrollbar-track {
  background-color: #fafafa;
}

/* Handle */
.HealthCheckExamDialog-exam-paper::-webkit-scrollbar-thumb {
  background-color: #e0e0e0; 
  border-radius: 10px;
}

/* Handle on hover */
.HealthCheckExamDialog-exam-paper::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd; 
}

.HealthCheckExamDialog-student-table {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  overflow-y: scroll;
}

.HealthCheckExamDialog-student-table::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.HealthCheckExamDialog-student-table::-webkit-scrollbar-track {
  background-color: #fafafa;
}

/* Handle */
.HealthCheckExamDialog-student-table::-webkit-scrollbar-thumb {
  background-color: #e0e0e0; 
  border-radius: 10px;
}

/* Handle on hover */
.HealthCheckExamDialog-student-table::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd; 
}

.HealthCheckExamDialog-question-table {
  width: 100%;
  height: calc(100vh - 530px);
  overflow-y: scroll;
}

.HealthCheckExamDialog-question-table::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.HealthCheckExamDialog-question-table::-webkit-scrollbar-track {
  background-color: #fafafa;
}

/* Handle */
.HealthCheckExamDialog-question-table::-webkit-scrollbar-thumb {
  background-color: #e0e0e0; 
  border-radius: 10px;
}

/* Handle on hover */
.HealthCheckExamDialog-question-table::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd; 
}