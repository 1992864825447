.ExamListPage-filter-paper {
  width: 100%;
  padding: 10px 0;
  margin-bottom: 10px;
}

.ExamListPage-main-div {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.ExamListPage-exams-table {
  width: 100%;
  height: calc(100vh - 140px);
}

.ExamListPage-exams-table::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.ExamListPage-exams-table::-webkit-scrollbar-track {
  background-color: #fafafa;
}

/* Handle */
.ExamListPage-exams-table::-webkit-scrollbar-thumb {
  background-color: #cfd8dc; 
  border-radius: 10px;
}

/* Handle on hover */
.ExamListPage-exams-table::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd;
}