.GradingAnswerDrawingDisplay-root-grid {
  height: 100%;
}

.GradingAnswerDrawingDisplay-display {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
}

.GradingAnswerDrawingDisplay-image {
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.GradingAnswerDrawingDisplay-drawing {
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.GradingAnswerDrawingDisplay-overlay-bottom-left {
  position: absolute;
  bottom: 70px;
  left: 10px;
  z-index: 101;
}
