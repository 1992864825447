.ExamTemplateEditorPage-filter-paper {
  width: 100%;
  padding: 10px 0;
  margin-bottom: 10px;
}

.ExamTemplateEditorPage-main-div {
  width: 100%;
  height: calc(100vh - 50px);
  padding: 10px 10px 0 10px;
}

.ExamTemplateEditorPage-case-table {
  width: 100%;
  height: calc(100vh - 140px);
  overflow-y: scroll;
}

.ExamTemplateEditorPage-case-table::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.ExamTemplateEditorPage-case-table::-webkit-scrollbar-track {
  background-color: #fafafa;
}

/* Handle */
.ExamTemplateEditorPage-case-table::-webkit-scrollbar-thumb {
  background-color: #e0e0e0; 
  border-radius: 10px;
}

/* Handle on hover */
.ExamTemplateEditorPage-case-table::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd; 
}

.ExamTemplateEditorPage-exam-table {
  width: 100%;
  height: calc(100vh - 120px);
  margin-bottom: 10px;
  overflow-y: scroll;
}

.ExamTemplateEditorPage-exam-table::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.ExamTemplateEditorPage-exam-table::-webkit-scrollbar-track {
  background-color: #fafafa;
}

/* Handle */
.ExamTemplateEditorPage-exam-table::-webkit-scrollbar-thumb {
  background-color: #e0e0e0; 
  border-radius: 10px;
}

/* Handle on hover */
.ExamTemplateEditorPage-exam-table::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd; 
}