.ExamTemplateSectionRow-section-descrption {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.ExamTemplateSectionRow-question-table {
  width: 100%;
  height: calc(100vh - 300px);
  overflow-y: scroll;
}

.ExamTemplateSectionRow-question-table::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.ExamTemplateSectionRow-question-table::-webkit-scrollbar-track {
  background-color: #fafafa;
}

/* Handle */
.ExamTemplateSectionRow-question-table::-webkit-scrollbar-thumb {
  background-color: #e0e0e0; 
  border-radius: 10px;
}

/* Handle on hover */
.ExamTemplateSectionRow-question-table::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd; 
}